import styled from 'styled-components'
import { flow, horizontalPadding } from '../../styles/utils/functions.style'
import { colors } from '../../styles/vars/colors.style'
import { font } from '../../styles/vars/font.style'
import { zIndex } from '../../styles/vars/layout.style'
import { mq } from '../../styles/vars/media-queries.style'

export const PrivacyMain = styled.div`
  position: relative;
  padding-top: 11.2rem;
  padding-bottom: 11.2rem;
  ${horizontalPadding()}
  z-index: ${zIndex.privacyPage};
  background-color: ${colors.light3};

  ${mq.horizontal} {
    padding-top: 19rem;
  }
`

export const PrivacySidebar = styled.nav`
  display: none;

  ${mq.horizontal} {
    position: sticky;
    top: 3.6rem;
    display: block;
  }
`

export const PrivacySidebarTitle = styled.h3`
  margin-bottom: 2rem;
  padding-bottom: 1.6rem;
  font-family: ${font.secondary};
  font-variation-settings: ${font.weight.secondary.medium};
  text-transform: uppercase;
  font-size: 2.8rem;
  border-bottom: 0.1rem solid ${colors.dark};
`

export const PrivacySidebarLink = styled.button`
  display: block;
  font-family: ${font.secondary};
  font-variation-settings: ${font.weight.secondary.medium};
  text-transform: uppercase;
  font-size: 2.1rem;
  border: none;
  background: transparent;
`

export const PrivacyContent = styled.div``

export const PrivacyTitle = styled.h2`
  margin-bottom: 2.8rem;
  padding-top: 0.07em;
  padding-bottom: 0.07em;
  font-family: ${font.secondary};
  font-variation-settings: ${font.weight.secondary.bold};
  font-size: 13.9rem;
  text-transform: uppercase;
  line-height: 0.7379856115;
`

export const PrivacyBlock = styled.div`
  & + & {
    margin-top: 3.2rem;
  }
`

export const PrivacyBlockContent = styled.div`
  margin-top: 1rem;
  font-size: 1.8rem;
  ${flow()}

  ul,ol {
    margin-left: 1.2em;
  }
`

export const PrivacyBlockTitle = styled.div`
  font-size: 2.1rem;
  line-height: 1.5;
  font-weight: ${font.weight.bold};
  letter-spacing: -0.02em;
`
