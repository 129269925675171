import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import Privacy from '../components/Privacy'

const PrivacyPolicy = ({ data }) => {
  const {
    title,
    seoDescription: { seoDescription },
    content,
  } = data.allContentfulPagePrivacyPolicy.nodes[0]

  return (
    <>
      <SEO title={title} description={seoDescription} />

      <Privacy title={title} content={content} />
    </>
  )
}

export default PrivacyPolicy

export const pageQuery = graphql`
  query PrivacyPageQuery {
    allContentfulPagePrivacyPolicy {
      nodes {
        title
        seoDescription {
          seoDescription
        }
        content {
          content {
            raw
          }
          title
        }
      }
    }
  }
`
