import React from 'react'
import PropTypes from 'prop-types'
import AnimateSlideIn from '../_animation/AnimateSlideIn'
import InViewSection from '../InViewSection'
import RichText from '../RichText'
import {
  PrivacyBlock,
  PrivacyBlockContent,
  PrivacyBlockTitle,
  PrivacyContent,
  PrivacyMain,
  PrivacySidebar,
  PrivacySidebarLink,
  PrivacySidebarTitle,
  PrivacyTitle,
} from './index.style'
import { animation } from '../../styles/vars/animation.style'
import Grid from '../_layouts/Grid'
import GridItem from '../_layouts/GridItem'
import { scrollToGSAP } from '../../utils/utils'

const Privacy = ({ title, content }) => {
  const getID = str => str.replace(/\s/g, '-').toLowerCase()

  return (
    <PrivacyMain>
      <Grid>
        <GridItem horizontal={3} horizontalStart={2}>
          <PrivacySidebar>
            <PrivacySidebarTitle>{title}</PrivacySidebarTitle>

            {React.Children.toArray(
              content.map(section => (
                <PrivacySidebarLink
                  onClick={() => scrollToGSAP(getID(section.title))}
                >
                  {section.title}
                </PrivacySidebarLink>
              ))
            )}
          </PrivacySidebar>
        </GridItem>

        <GridItem horizontal={7} horizontalStart={6}>
          <PrivacyContent>
            <InViewSection threshold={0}>
              <>
                <AnimateSlideIn direction="left">
                  <PrivacyTitle>{title}</PrivacyTitle>
                </AnimateSlideIn>

                {React.Children.toArray(
                  content.map(section => (
                    <PrivacyBlock id={getID(section.title)}>
                      <PrivacyBlockTitle>{section.title}</PrivacyBlockTitle>
                      <PrivacyBlockContent>
                        <RichText
                          content={section.content}
                          delay={animation.pageExitDuration}
                        />
                      </PrivacyBlockContent>
                    </PrivacyBlock>
                  ))
                )}
              </>
            </InViewSection>
          </PrivacyContent>
        </GridItem>
      </Grid>
    </PrivacyMain>
  )
}

Privacy.propTypes = {
  title: PropTypes.string,
  content: PropTypes.array,
}

export default Privacy
